<template>
  <select>
    <slot></slot>
  </select>
</template>

<script>
/* global $ */
export default {
  props: ["options", "value"],
  mounted: function() {
    var vm = this;
    $(this.$el)
      // init select2
      .select2({ data: this.options, theme: 'bootstrap4',
				width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style' })
      .val(this.value)
      .trigger("change")
      // emit event on change.
      .on("change", function() {
        vm.$emit("update:modelValue", this.value);
      });
  },
  watch: {
    value: function(value) {
      // update value
      $(this.$el)
        .val(value)
        .trigger("change");
    },
    options: function(options) {
      // update options
      $(this.$el)
        .empty()
        .select2({ data: options, theme: 'bootstrap4',
				width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style' });
    }
  },
  unmounted: function() {
    $(this.$el)
      .off()
      .select2("destroy");
  }
      
}
</script>