<template>
  <div>

    <div class="card">
      <div class="card-body">
        <div class="form-check form-switch mb-2" style="max-width: 200px;">
          <input class="form-check-input" v-model="showDisabled" @change="loadUsers(1)" type="checkbox" id="flexSwitchCheckDefault">
          <label class="form-check-label" for="flexSwitchCheckDefault">Show Disabled Users</label>
        </div>

        <div class="position-relative wd-50">
          <input v-on:keyup="loadUsers(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Users..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>

        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createUserModal" class="btn btn-primary px-5">New User</button>
        <pagination @search="loadUsers" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id" style="cursor: pointer;" @click="$router.push({name: 'admin.management.users.view', params: {id: user.id}})">
              <td>{{user.id}}</td>
              <td>{{user.fname}}</td>
              <td>{{user.lname}}</td>
              <td>{{user.email}}</td>
              <td>{{user.status}}</td>
              <td>{{user.type}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Type</th>
            </tr>
          </tfoot>
        </table>

        <pagination @search="loadUsers" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createUserModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create User</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="userFname" class="form-label">First Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="userFname" v-model="user.fname">
              </div>
              <div class="col-md-12 mb-2">
                <label for="userLname" class="form-label">Last Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="userLname" v-model="user.lname">
              </div>
              <div class="col-md-12 mb-2">
                <label for="userEmail" class="form-label">Email Address <span class="text-danger">*</span></label>
                <input type="email" class="form-control" id="userEmail" v-model="user.email">
              </div>
              <div class="col-md-12 mb-2">
                <label for="userStatusSelect" class="form-label">Status <span class="text-danger">*</span></label>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="Active" id="userStatusActive" v-model="user.status">
                  <label class="form-check-label" for="userStatusActive">Active</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" value="Disabled" id="userStatusDisabled" v-model="user.status">
                  <label class="form-check-label" for="userStatusDisabled">Disabled</label>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <label for="userType" class="form-label">Type <span class="text-danger">*</span></label>
                <select-2 id="userType" v-model="user.type" :value="user.type" class="single-select" :options="types">
                  <option selected disabled>Select a Type</option>
                </select-2>
              </div>
              <div class="col-md-12 mt-2">
                <p>Passwords must be at least 8 characters long and contain 1 each of uppercase letter, lowercase letter and number.</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="userPassword" class="form-label">Password <span class="text-danger">*</span></label>
                <input type="password" class="form-control" id="userPassword" v-model="user.password">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateUserModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createUser" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../../components/Pagination.vue';
import Select2 from '../../../../components/Select2.vue';
import SubmitButton from '../../../../components/SubmitButton.vue';
export default {
  name: 'admin.management.users',
  components: {
    Pagination,
    SubmitButton,
    Select2
  },
  data(){
    return {
      users: [],
      types: [],
      showDisabled: false,
      pagination: {
        page: 0,
        showing: 0,
        total: 0,
        lastPage: 0
      },
      creating: false,
      user: {
        fname: null,
        lname: null,
        email: null,
        status: 'Active',
        password: null,
        type: 1
      },
      filters: {
        search: ""
      }
    }
  },
  mounted(){
    this.loadUsers();
    this.loadUserTypes();
    $('#userStatusSelect').select2({
				theme: 'bootstrap4',
				width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
				placeholder: "Select a Status",
				allowClear: false
			});
    $('.single-select').select2({
      theme: 'bootstrap4',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: "Select a Type",
      allowClear: false
    });

  },
  methods: {
    loadUsers(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users?page=${page}${this.showDisabled ? '&disabled=true' : ''}&search=${this.filters.search}`)
      .then(response => {
        this.users = response.data.users;
        this.pagination = response.data.pagination
      })
      .catch(error => {
        this.$error("Failed to load users", error);
      });
    },
    createUser(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/users`, this.user)
      .then(() => {
        $('#closeCreateUserModal').click();
        this.$success("Created new user");
        this.creating = false;
        this.user = {
          fname: null,
          lname: null,
          email: null,
          status: 'Active',
          password: null,
          type: 1
        }
        this.loadUsers(this.pagination.page);
      })
      .catch(error => {
        this.$error("Failed to create new user.", error);
        this.creating = false;
      })
    },
    loadUserTypes(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/types/list`)
      .then(response => {
        this.types = response.data.types;
        this.types.forEach((type) => {
          type.text = type.name
        });
      })
      .catch(error => {
        this.$error("Failed to load User Types", error);
      })
    }
  }
}
</script>